import React, { useEffect, useState, useContext } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import LayoutNoStripe, { AuthContext } from "../../components/layoutNoStripe";
import { Section, SectionHeaderLedeDiv, SectionHeading } from "../../styledComponents/section";
import { EventListSection } from "../../styledComponents/eventList"
import ProductList from "../../components/ProductListPurchased"
import { StyledButton } from "../../styledComponents/button";


const Heading = ({ level = 1, location, children, offScreen }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} offScreen={offScreen} className="my-account__header">
    {children}
  </SectionHeading>
)

const MyAccount = ({ data, location }) => {
  const [roles, setRoles] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const { isAuthenticated, user, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
  console.log("AuthContext Provider Values MyAccount: ", { isAuthenticated, user, roles });
  let allproducts = data.allWpProducts.edges


  // Declare hooks unconditionally
  const [tabIndex, setTabIndex] = useState(0);
  const [processing, setProcessing] = useState(false);
  const fetchRoles = async () => {
    if (!isAuthenticated) {
      setRoles([]);
      setLoadingRoles(false);
      return;
    }

    try {
      const userRoles = user?.["https://www.karinagrant.co.uk/roles"] || [];

      if (userRoles.length > 0) {
        setRoles(userRoles);
      } else {
        const token = await getAccessTokenSilently({ ignoreCache: true });
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const tokenRoles = decodedToken["https://www.karinagrant.co.uk/roles"] || [];
        setRoles(tokenRoles);
      }
    } catch (error) {
      console.error("Error fetching roles:", error);
      setRoles([]);
    } finally {
      setLoadingRoles(false);
    }
  };
  const today = new Date()
  const curHr = today.getHours()
  let greetingText
  if (curHr < 12) {
    greetingText = 'Good morning'
  } else if (curHr < 18) {
    greetingText = 'Good afternoon'
  } else {
    greetingText = 'Good evening'
  }

  const handleLogin = () => {
    loginWithRedirect({
      redirectUri: window.location.origin + '/my-account/',
    });
  };
  useEffect(() => {
    fetchRoles();

    const interval = setInterval(() => {
      fetchRoles();
    }, 15 * 1000);

    return () => clearInterval(interval);
  }, [isAuthenticated, user, getAccessTokenSilently]);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const prod = urlParams.get("product");

    if (prod === "quantum-touch" || prod === "quantum-touch-pendants-malas") {
      setTabIndex(1);
    } else if (prod === "meditation" || prod === "meditations") {
      setTabIndex(2);
    } else if (prod === "program" || prod === "programs") {
      setTabIndex(3);
    }
  }, []);
  console.log('user',user)
  return (
    <>
      <Helmet>
        <meta name={`robots`} content={`noindex, nofollow`} />
      </Helmet>
      <LayoutNoStripe location={location} displayNavBorder>
        <Section>
          {isAuthenticated ? (
            <>
              <Heading level={1} className="hidden" offScreen={false}>{greetingText}, {user?.given_name || "Guest"} </Heading>
              <EventListSection className="event-list">
                <ProductList posts={allproducts} roles={roles} isAuthenticated={isAuthenticated} user={user}/>
              </EventListSection>
            </>
          ) : (
            <>
              <Heading level={1} align="center">
                Log in
              </Heading>
              <SectionHeaderLedeDiv>
                <p>Please login to access your account.</p>
                <StyledButton onClick={handleLogin}>Login</StyledButton>
              </SectionHeaderLedeDiv>
            </>
          )}
        </Section>
      </LayoutNoStripe>
    </>
  );
};

export default MyAccount;

export const pageQuery = graphql`
  query WordPressMyAccountArchive {
    allWpProducts: allWpProduct(
      sort: { order: ASC, fields: menuOrder }
      filter: { title: { ne: "Permission Bonus" } }
    ) {
      edges {
        node {
          title
          uri
          id
          menuOrder
          excerpt
          productsTag {
            nodes {
              name
              slug
            }
          }
          productData {
            linkToAmazon
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            pricefullusd
            pricesaleusd
            stripefullpriceusd
            stripesalepriceusd
            hidesale
            hideonarchive
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
  }
`;

